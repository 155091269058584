/*------------------------------------------------------------------
  [Notifications]
*/

// Gritter mobile media query
@mixin gritter-mobile {
  @media (max-width: $notification-mobile-breakpoint) {
    @content;
  }
}

#gritter-notice-wrapper {
  width: $notification-width;
  top: $notification-top-position;

  // Small devices
  @include gritter-mobile {
    right: 0;
    width: 100%;
    padding: 0 10px;
  }
}

//Social Color Mixin
@mixin social-gritter($background) {
  background: $background;

  .gritter-item {
    .gritter-img-container {
      background-color: darken($background, 3%);
    }
  }
}

.gritter-item-wrapper {
  background: url("#{$lib-path}/jquery.gritter/images/gritter-bg.png");
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);

  .gritter-item {
    font-family: $roboto;
    background: transparent;
    color: theme-color("ligth");
    padding: 0;
    display: flex;

    .gritter-img-container {
      display: flex;
      flex: 0 0 94px;
      align-items: center;
      justify-content: center;
      background-color: darken(theme-color("dark"), 5%);

      @include gritter-mobile {
        flex: 0 0 80px;
      }

      .gritter-image {
        float: none;
      }
    }

    .gritter-content {
      flex: 1 1 100%;

      &.gritter-with-image,
      &.gritter-without-image {
        padding: 15px 35px 17px 15px;
        color: darken(theme-color("light"), 15%);

        @include gritter-mobile {
          padding: 10px 25px 10px 13px;
        }

        .gritter-close {
          display: block;
          top: 0;
          right: 0;
          left: auto;
          height: 30px;
          width: 30px;
          font-size: 0;
          background: transparent;
          text-indent: inherit;
          opacity: 0.4;

          &:hover {
            opacity: 0.8;
          }

          &:after {
            content: "\f136";
            font-family: $icons-font;
            color: theme-color("light");
            font-size: 1.538rem;
            text-shadow: none;
            line-height: 35px;
            margin-left: 7px;
          }
        }

        .gritter-title {
          text-shadow: none;
          font-weight: 300;
          font-size: 1.308rem;
        }

        p {
          font-size: 0.9231rem;
          line-height: 19px;
        }
      }

      .gritter-without-image {
        border-left: 0;
      }
    }
  }

  &.img-rounded {
    border-radius: 0;

    .gritter-img-container {
      .gritter-image {
        border-radius: 50%;
      }
    }
  }

  &.clean {
    background: theme-color("light");

    .gritter-item {
      color: $body-color;

      .gritter-img-container {
        background-color: darken(theme-color("light"), 2%);
      }

      .gritter-content {
        color: $body-color;

        .gritter-close {
          opacity: 0.5;

          &:after {
            color: $body-color;
          }
        }
      }
    }
  }

  &.color {
    .gritter-item {
      .gritter-content {
        color: theme-color("light");

        .gritter-close {
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &.primary {
    background: theme-color("primary");
  }

  &.success {
    background: theme-color("success");
  }

  &.warning {
    background: theme-color("warning");
  }

  &.danger {
    background: theme-color("danger");
  }

  &.dark {
    background: theme-color("dark");
  }

  &.facebook {
    @include social-gritter($facebook);
  }

  &.twitter {
    @include social-gritter($twitter);
  }

  &.google-plus {
    @include social-gritter($google-plus);
  }

  &.dribbble {
    @include social-gritter($dribbble);
  }

  &.flickr {
    @include social-gritter($flickr);
  }

  &.linkedin {
    @include social-gritter($linkedin);
  }

  &.youtube {
    @include social-gritter($youtube);
  }

  &.pinterest {
    @include social-gritter($pinterest);
  }

  &.github {
    @include social-gritter($github);
  }

  &.tumblr {
    @include social-gritter($tumblr);
  }
}

@include gritter-mobile {
  .gritter-image {
    max-width: 32px;
    max-height: 32px;
  }
}